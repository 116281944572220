<template>
	<div class="card pop">
		<div class="header">
			<h1 class="heading-3-b">동시 접속 안내</h1>
			<button class="icon" @click="close">
				<i class="ic-24 ic-close"></i>
			</button>
		</div>
		<div class="contents-wrap">
			<p class="body-2-r text-center">
				현재 PC에서 로그인 하시겠습니까?
				<br />
				동일한 계정으로 동시 접속은 불가하며, 로그인 시 동시 접속중인 <em class="point">{{ loginId }}</em
				>계정은 강제 로그아웃 됩니다.
			</p>
		</div>
		<div class="btn-area group-btn">
			<button class="solid" @click="concurrentLogin">로그인</button>
			<button class="line" @click="close">취소</button>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { mixinLinkAccount } from '@/components/login/mixinsLinkAccount';

export default {
	name: 'ConcurrentAccess',
	props: {
		name: {
			type: String,
			default: 'ConcurrentAccess',
		},
		loginId: {
			type: String,
			default: '',
		},
		token: {
			type: String,
			default: '',
		},
	},
	mixins: [mixinLinkAccount],
	methods: {
		close(e) {
			e.preventDefault();
			this.$modal.hide(this.name);
			this.$router.push('/login');
		},
		concurrentLogin() {
			if (_.isEmpty(this.token)) {
				return;
			}
			sessionStorage.setItem('authToken', this.token);
			this.$store.dispatch('loginConcurrentAccess').then(() => {
				this.linkService().finally(() => this.$router.push('/home'));
				this.$modal.hide(this.name);
				return;
			});
			this.$modal.hide(this.name);
		},
	},
};
</script>

<style scoped>
@import '../../assets/css/common.css';
</style>

<template>
	<Fragment>
		<div class="header">
			<h1 class="heading-1-b">기업회원 계정을 생성해 주세요.</h1>
			<!-- **** 클로즈 버튼 없음 **** -->
			<!-- **** 팝업 바깥영역 클릭 -> 창을 닫는 것도 금지 **** -->
			<!-- 이미지 : 기능 없음 -->
			<i class="logo"></i>
		</div>
		<!-- // style에 들어있는 스타일은 본 문서에서만 사용합니다. -->
		<!-- **** div.header 없음 **** -->
		<div class="contents-wrap">
			<p class="subtitle">
				고객포털은 기업마다 반드시 기업회원 계정을 가져야하며,<br />
				기업회원 계정으로 사용자관리에서 초대를 통해 계약 담당자를 추가할 수 있습니다.
			</p>
			<p class="description">
				다우오피스 주문(계약)과 관련해 고객 법인정보 및 계약 담당자 개인정보 보호 강화 조치로 2023년 9월 21일(목)부터
				<br />
				새로운 고객포털 서비스가 제공되며 회원가입 절차를 통해 기업 회원 계정으로 서비스를 이용하게 됩니다.
			</p>
			<p class="description">
				기존 계약 정보 연결을 위해 다우오피스 사용 고객님들께 기업회원 가입 초대장을 발송하였으나,<br />
				혹여 받지 못해 기업회원으로 가입을 못하신 분은 초대장 재 발송 기능을 통해 가입해 주시기 바랍니다.
			</p>
			<br />
			<h2>기업 회원 초대장 다시 받기</h2>
			<div class="form">
				<div class="group column mb-16">
					<!-- basic > invalid -->
					<div class="group-inp">
						<label for="testText01" class="label asterisk">이름</label>
						<input type="text" v-model="inviteInfo.name" @input="validateName" placeholder="이름을 입력해 주세요." />
						<!-- invalid message -->
						<p
							v-if="inviteInvalidClass.nameReason"
							class="text-detail"
							:class="[inviteInvalidClass.name ? 'invalid' : 'valid']"
						>
							<i class="ic-16" :class="[inviteInvalidClass.name ? 'ic-invalid' : 'ic-valid']"></i>
							{{ inviteInvalidClass.nameReason }}
						</p>
					</div>
				</div>
				<div class="group column mb-16">
					<!-- basic > invalid -->
					<div class="group-inp">
						<label for="testText01" class="label asterisk">이메일 주소</label>
						<input
							type="text"
							v-model="inviteInfo.email"
							@input="validateEmail"
							placeholder="입력한 이메일 주소로 초대장이 전송됩니다."
						/>
						<!-- invalid message -->
						<p
							v-if="inviteInvalidClass.emailReason"
							class="text-detail"
							:class="[inviteInvalidClass.email ? 'invalid' : 'valid']"
						>
							<i class="ic-16" :class="[inviteInvalidClass.email ? 'ic-invalid' : 'ic-valid']"></i>
							{{ inviteInvalidClass.emailReason }}
						</p>
					</div>
				</div>
				<div class="btn-area">
					<button class="solid full" @click="inviteCustomerAccount">초대장 발송</button>
				</div>
				<p class="remarks">※ 이 가이드는 기업회원 계정이 생성될 때까지 노출됩니다. (문의사항 1577-3019)</p>
			</div>
		</div>
	</Fragment>
</template>

<script>
import { checkEmail } from '@/plugins/custom/modules/validator';
import { INVALID_EMAIL, VALID_EMAIL, EMPTY_INPUT, VALID_NAME, ALREADY_USED_EMAIL } from '@/temlplates';
import mixinsErrCodeObserver from '@/components/exception/mixinsErrCodeObserver';
import GnbAuthInviteSuccessModal from '@/components/login/GnbAuthInviteSuccessModal';
import { CUSTOMER_INVITE_SUCCESS_MODAL } from '@/utils/constants';
import { sendCustomerAccountInviteEmail } from '@/api/user';
import _ from 'lodash';
import { duplicationEmail } from '@/api/login';
export default {
	data() {
		return {
			inviteInfo: {
				email: '',
				name: '',
			},
			inviteInvalidClass: {
				name: true,
				nameReason: '',
				email: true,
				emailReason: '',
			},
		};
	},
	props: {
		daouofficeCode: {
			type: String,
			default: '',
		},
	},
	mixins: [mixinsErrCodeObserver],
	methods: {
		validateEmail(e) {
			if (checkEmail(e.currentTarget.value)) {
				this.inviteInvalidClass.email = false;
				this.inviteInvalidClass.emailReason = VALID_EMAIL;
			} else {
				this.inviteInvalidClass.email = true;
				this.inviteInvalidClass.emailReason = INVALID_EMAIL;
			}
		},
		validateName(e) {
			this.inviteInvalidClass.name = _.isEmpty(e.target.value.trim());
			this.inviteInvalidClass.nameReason = this.inviteInvalidClass.name ? EMPTY_INPUT : VALID_NAME;
		},
		async inviteCustomerAccount() {
			if (this.inviteInvalidClass.name || this.inviteInvalidClass.email) {
				this.inviteInvalidClass.nameReason = this.inviteInvalidClass.name ? EMPTY_INPUT : VALID_NAME;
				this.inviteInvalidClass.emailReason = this.inviteInvalidClass.email ? INVALID_EMAIL : VALID_EMAIL;
				return;
			}

			try {
				const param = {
					email: this.inviteInfo.email,
					name: this.inviteInfo.name,
					daouofficeCode: this.daouofficeCode,
				};
				await sendCustomerAccountInviteEmail(param);
				this.handleClose();
				this.$modal.show(
					GnbAuthInviteSuccessModal,
					{ email: this.inviteInfo.email, name: this.inviteInfo.name },
					Object.assign({}, CUSTOMER_INVITE_SUCCESS_MODAL, { clickToClose: false }),
				);
			} catch (res) {
				this.observeErrCode(res.errorCode, res.errorMessage, this.$toasted.show);
			}
		},
		handleClose() {
			this.$emit('close');
		},
	},
};
</script>

<style scoped></style>

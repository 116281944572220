var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"select-list"},[_c('li',[_c('div',{staticClass:"group-rdo large"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedMethod),expression:"selectedMethod"}],attrs:{"type":"radio","id":"testRadio01","name":"testSelectOption","value":"email"},domProps:{"checked":_vm._q(_vm.selectedMethod,"email")},on:{"change":function($event){_vm.selectedMethod="email"}}}),_c('label',{staticClass:"text-option",attrs:{"for":"testRadio01"}},[_c('div',{staticClass:"option-list"},[_c('p',{staticClass:"text-main"},[_vm._v("이메일 인증")]),_c('div',{staticClass:"group column gap8",class:{ blind: _vm.selectedMethod !== 'email' }},[_c('div',{staticClass:"group-inp block",class:{ invalid: _vm.invalidClass.name }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.name),expression:"name",modifiers:{"trim":true}}],attrs:{"type":"text","id":"name","maxlength":"50","placeholder":"이름"},domProps:{"value":(_vm.name)},on:{"input":[function($event){if($event.target.composing)return;_vm.name=$event.target.value.trim()},() => {
										if (_vm.name.length !== 0) {
											this.invalidClass.name = false;
										} else {
											this.invalidClass.name = true;
										}
									}],"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.invalidClass.name)?_c('p',{staticClass:"text-detail",class:[_vm.invalidClass.name ? 'invalid' : 'valid']},[_c('i',{staticClass:"ic-16",class:[_vm.invalidClass.name ? 'ic-invalid' : 'ic-valid']}),_vm._v("이름을 입력해주세요 ")]):_vm._e()]),_c('div',{staticClass:"group-inp block",class:{ invalid: _vm.invalidClass.birth }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.birthDay),expression:"birthDay",modifiers:{"trim":true}}],class:[_vm.invalidClass.birth ? 'invalid' : 'valid'],attrs:{"type":"text","id":"birthday","placeholder":"회원정보에 등록한 생년월일 (ex19970415)","maxlength":"10"},domProps:{"value":(_vm.birthDay)},on:{"input":[function($event){if($event.target.composing)return;_vm.birthDay=$event.target.value.trim()},_vm.validateBirth],"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.invalidClass.birth)?_c('p',{staticClass:"text-detail",class:[_vm.invalidClass.birth ? 'invalid' : 'valid']},[_c('i',{staticClass:"ic-16",class:[_vm.invalidClass.birth ? 'ic-invalid' : 'ic-valid']}),_vm._v("생년월일을 확인해주세요 ")]):_vm._e()]),_c('div',{staticClass:"group-inp block",class:{ invalid: _vm.invalidClass.email }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],attrs:{"type":"text","id":"email","maxlength":"50","placeholder":"이메일 주소 (계정)"},domProps:{"value":(_vm.email)},on:{"input":[function($event){if($event.target.composing)return;_vm.email=$event.target.value.trim()},() => {
										this.invalidClass.email = !_vm.checkEmail(this.email);
									}],"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.invalidClass.email)?_c('p',{staticClass:"text-detail",class:[_vm.invalidClass.email ? 'invalid' : 'valid']},[_c('i',{staticClass:"ic-16",class:[_vm.invalidClass.email ? 'ic-invalid' : 'ic-valid']}),_vm._v("이메일 형식을 확인해주세요 ")]):_vm._e()]),_c('button',{staticClass:"solid",class:{ disabled: _vm.selectedMethod !== 'email' },attrs:{"disabled":_vm.selectedMethod !== 'email'},on:{"click":_vm.handleCompleteForEmail}},[_vm._v(" 계정 찾기 ")])])])])])]),_c('li',[_c('div',{staticClass:"group-rdo large"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedMethod),expression:"selectedMethod"}],attrs:{"type":"radio","id":"testRadio02","name":"testSelectOption","value":"phone"},domProps:{"checked":_vm._q(_vm.selectedMethod,"phone")},on:{"change":function($event){_vm.selectedMethod="phone"}}}),_c('label',{staticClass:"text-option",attrs:{"for":"testRadio02"}},[_c('div',{staticClass:"option-list"},[_c('p',{staticClass:"text-main"},[_vm._v("현재 사용중인 휴대전화로 인증(본인인증)")]),_c('div',{staticClass:"group column gap8",class:{ blind: _vm.selectedMethod === 'email' }},[_c('IdentityVerificationButton',{on:{"complete":_vm.handleComplete}})],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
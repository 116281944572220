<template>
	<div class="card pop notice">
		<div class="header">
			<button @click="handleCloseClick" class="icon">
				<i class="ic-24 ic-close-thin"></i>
				close
			</button>
			<!-- 변경 사항 -->
			<div class="notice-header">
				<h1 class="title">
					멤버십스토어가
					<em>고객포털로 전환됩니다.</em>
				</h1>
				<div class="sub-info">
					<span class="badge round primary">전환시기</span>
					<span class="date">2023년 9월 21일 (목)</span>
				</div>
			</div>
		</div>

		<div class="contents-wrap">
			<p>
				안녕하세요 다우오피스입니다. <br />
				<br />
				다우오피스의 주문(계약) 관련 <em>고객의 법인정보 및 계약 담당자 개인정보 보호 강화조치로</em> 2023년 9월
				21일(목)부터 기존 멤버십스토어는 종료되고
				<br />
				<em>새로운 고객포털 서비스</em>가 제공됩니다.<br />
				<br />
				기존 멤버십스토어에서는 주문, 계약 이력, 담당자 등의 개인정보 등 다수의 사이트 관리자가 접근하여 관리되었으나
				<br />
				고객포털 서비스 오픈 후부터는 <em>회원가입으로 기업 대표 계정</em>을 통해 주문 및 계약관리, 담당자 개인정보 등을
				관리할 수 있게 됩니다.<br />
				<br />
				2023년 9월 21일(목) <em>고객포털 오픈 후 기존 멤버십스토어는 이용 불가</em>로 다우오피스 주문(계약)담당자는
				기업회원 가입을 통해 기업 대표 계정으로
				<br />
				서비스를 이용해주시기 바랍니다.<br />
				<em>
					📢
					<a
						href="https://care.daouoffice.co.kr/hc/ko/articles/19612430792217"
						target="_blank"
						style="color: #000; text-decoration: none"
						>내용 자세히 보기</a
					>
				</em>
			</p>
			<ol class="highlight mt-20">
				<li>고객포털 회원가입 / 로그인을 통해 담당 권한자만 접근해 주세요.</li>
				<li>고객포털 로그인 후 회사 법인정보와 멤버십 정보 등이 주문(계약) 정보와 맞는지 반드시 확인해 주세요.</li>
				<li>
					두 개 이상의 법인으로 다우오피스를 이용하고 계신 경우 (공유형 멀티컴퍼니) 각 컴퍼니마다 다른 계약 담당자를
					설정하셔야 합니다.
				</li>
				<li>
					하나의 법인으로 여러 서비스를 이용하고 계신 경우에는 고객포털에서 모든 멤버십(계약) 정보를 조회하실 수
					있습니다. 계약정보가 모두 노출되지 않을 경우 영업담당자나 고객센터로 문의 주시기 바랍니다.
				</li>
			</ol>
			<p class="mt-20">위와 관련한 문의 사항은 1577-3019로 연락주시기 바랍니다.</p>
			<p class="">감사합니다.</p>
		</div>
		<div class="outside-button">
			<button @click="handleReadClick" class="icon-text body-2-m">
				<i class="ic-16 ic-close"></i>
				다시 보지 않기
			</button>
		</div>
	</div>
</template>

<script>
import { NOTICE_ID } from '@/constants';

export default {
	methods: {
		handleCloseClick() {
			this.$modal.hide('notice');
		},
		handleReadClick() {
			this.handleCloseClick();
			localStorage.setItem('lastReadNoticeId', NOTICE_ID);
		},
	},
};
</script>

<style scoped></style>

<template>
	<div>
		<div class="header">
			<h1 class="heading-3-b">비밀번호 초기화</h1>
			<button class="icon" @click="handleClose">
				<i class="ic-24 ic-close"></i>
			</button>
		</div>

		<div class="contents-wrap">
			<div v-if="step === 0">
				<p class="body-2-r mb-24">본인 인증 후 회원 정보에 등록한 이메일로 임시 비밀번호를 보내드립니다.</p>
				<AuthenticationComp v-on:complete="handleNextStep" />
			</div>
			<div v-if="step === 1">
				<p class="body-2-r mb-24">
					회원정보에 등록한 이메일 주소를 확인해주세요. <br />
					해당 메일로 임시 비밀번호를 전송합니다.
				</p>
				<FindAccountResultComp :accountList="accountList" v-on:check="handleCheckAccount" :type="'resetPassword'" />
			</div>
		</div>

		<div class="btn-area group-btn" v-if="step === 0">
			<button class="line" @click="handleClose">취소</button>
		</div>
		<div class="btn-area group-btn" v-if="step === 1">
			<button class="line" @click="login">로그인</button>
			<button class="solid" @click="handleResetPassword">비밀번호 초기화</button>
		</div>
	</div>
</template>

<script>
import FindAccountResultComp from './FindAccountResultComp.vue';
import AuthenticationComp from './AuthenticationComp.vue';
import { resetPassword } from '../../api/login';
import mixinsErrCodeObserver from '../exception/mixinsErrCodeObserver';
import { checkIdentity } from '@/components/login/mixinsFindUser';

export default {
	name: 'ResetPasswordLayer',
	components: { AuthenticationComp, FindAccountResultComp },
	mixins: [mixinsErrCodeObserver, checkIdentity],
	data() {
		return {
			step: 0,
			accountList: Array,
			loginId: '',
		};
	},
	methods: {
		handleClose() {
			this.$emit('close');
		},
		handleNextStep(data) {
			if (data.accountList.length === 0) {
				this.notSigned();
				this.$emit('close');
			}
			this.step++;
			this.accountList = data.accountList;
		},
		handleCheckAccount(data) {
			this.selectedAccount = data.account;
			this.loginId = this.selectedAccount.loginId;
		},
		login() {
			this.$router.push('/login');
		},
		async handleResetPassword() {
			try {
				await resetPassword({
					id: this.selectedAccount.id,
					email: this.selectedAccount.email,
				});

				this.$toasted.show('비밀번호 초기화 메일이 발송되었습니다.');
				this.$emit('close');
			} catch (e) {
				if (e.data.errorCode === 'ERROR210') {
					this.$emit('close');
					return this.observeErrCode(e.data.errorCode, e.data.errorMessage, this.setLoginErrorMessage);
				} else {
					this.$toasted.show('비밀번호 초기화 메일 발송에 실패했습니다. 다시 시도해주세요');
				}
			}
		},
	},
};
</script>

<style scoped></style>

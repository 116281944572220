<template>
	<Fragment>
		<div class="header">
			<i class="graphic-check"></i>
			<h1 class="heading-3-b text-center">기업회원 초대장이 발송되었습니다!</h1>
			<!-- **** 클로즈 버튼 없음 **** -->
		</div>
		<div class="contents-wrap">
			<div class="info-area">
				<div class="group row">
					<label class="form-label">
						<span class="txt"> 이름</span>
					</label>
					<div class="form-value">
						<span class="txt">{{ name }}</span>
					</div>
				</div>
				<div class="group row">
					<label class="form-label">
						<span class="txt">이메일</span>
					</label>
					<div class="form-value">
						<span class="txt">{{ email }}</span>
					</div>
				</div>
			</div>
			<div class="btn-area pr-0 pl-0 pt-16 pb-24">
				<button class="line full" @click="handleClose">확인</button>
			</div>
		</div>
	</Fragment>
</template>

<script>
export default {
	props: {
		email: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
	},
	methods: {
		handleClose() {
			this.$store.commit('CLEAR_LINK_CODES');
			this.$router.push('/login');
			this.$emit('close');
		},
	},
};
</script>

<style scoped></style>

<template>
	<Fragment>
		<div class="footer">
			<div class="footer-head">
				<!-- logo -->
				<div class="logo">
					<i class="img-logo"></i>
				</div>
			</div>

			<div class="footer-contents">
				<!-- 회사정보 -->
				<ul class="footer-company-info">
					<li class="simple-info">
						<span>(주)다우기술 대표이사 김윤덕</span>
						<span>호스팅 제공 (주)다우기술</span>
						<span>사업자등록번호 220-81-02810</span>
						<span>통신판매: 제 2023–성남수정–1138호</span>
					</li>
					<li class="simple-info">
						<span>경기도 성남시 수정구 금토로 69, 다우디지털스퀘어 4층</span>
						<span>이메일: daouoffice@daou.co.kr</span>
					</li>
					<li class="link-info">
						<a href="https://daou.co.kr" target="_blank">회사소개</a>
						<a @click="openTermsType('TERMS_OF_USE')" target="_blank">이용약관</a>
						<a href="https://daouoffice.com/privacy.jsp" target="_blank" class="bold">개인정보처리방침</a>
						<a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208102810" target="_blank">사업자정보 확인</a>
					</li>
				</ul>
				<!-- 고객지원 -->
				<div class="footer-additional">
					<!-- util -->
					<div class="util">
						<div class="btn-area">
							<a :href="careLoungeUrl" target="_blank"><button class="solid square">고객케어라운지</button></a>
						</div>
					</div>

					<!-- @PORTAL-190 additional 정보 타이틀 삭제 -->
					<!-- <h5>고객지원</h5> -->
					<p>
						실시간 채팅 또는 전화 상담 <br />
						평일 09:00~17:30
					</p>
					<!-- @PORTAL-190 마크업 수정 -->
					<dl>
						<div>
							<dt>가입문의</dt>
							<dd>1577-3019</dd>
						</div>
						<!-- @PORTAL-190 additional 정보 삭제 -->
						<!-- <dt>기능문의</dt>
            <dd>1599-9460</dd> -->
						<div>
							<dt>긴급 장애 신고</dt>
							<dd>1599-9460</dd>
						</div>
					</dl>
				</div>
			</div>
		</div>
	</Fragment>
</template>
<script>
import { CARELOUNGEURL } from '@/constants';
import { getTermDetail } from '@/api/customer';
import termsTemplate from '@/components/login/TermsDetailLayer.vue';
import { TERMS_MODAL_STYLE } from '@/utils/constants';

export default {
	name: 'main-footer',
	computed: {
		careLoungeUrl() {
			return CARELOUNGEURL;
		},
	},
	methods: {
		chatHelpPopup() {
			window.open(
				'https://mydaouoffice.channel.io/support-bots/93',
				'popup_name',
				'width=420px, height=720px, location=no, top=' +
					window.screen.height +
					', left=' +
					window.screen.width +
					', status=no,scrollbars=yes',
			);
		},
		async openTermsType(termsType) {
			if (this.$store.getters.getCustomerTermsList.length === 0) {
				await this.$store.dispatch('setCustomerTermsList');
			}

			const customerTermsList = this.$store.getters.getCustomerTermsList;
			let terms = customerTermsList.find((terms) => terms.termsType === termsType);

			await getTermDetail(terms.termsId).then((response) => {
				this.$modal.show(
					termsTemplate,
					{
						termsTitle: terms.termsName,
						termsContent: response.data.termsContent,
					},
					Object.assign({}, TERMS_MODAL_STYLE, { name: 'termsTemplate', clickToClose: false }),
				);
			});
		},
	},
};
</script>

<style>
@import '../../assets/css/common.css';
@import '../../assets/css/main.css';
@import '../../assets/css/vue-carousel.css';
</style>

<template>
	<Fragment>
		<!-- nav -->
		<header>
			<!-- Logo area -->
			<h1 class="logo">
				<a href="/login" class="logo-link">
					<i class="img-logo"></i>
					고객 포털 서비스
				</a>
			</h1>
			<!-- util area : 로그인 후, util 영역 -->
		</header>
		<!-- section -->
		<div class="body-wrap login">
			<div class="card">
				<div class="login-container">
					<!-- @안내 이미지 영역 : vendor/vue-login-guide -->
					<div class="portal-guide">
						<carousel
							class="info-carousel"
							:navigationEnabled="false"
							:perPage="1"
							:autoplay="true"
							:autoplayTimeout="3000"
							paginationActiveColor="#00A1B9"
							paginationColor="#b1e7ed"
							:paginationPadding="4"
							:navigationClickTargetSize="0"
							:loop="true"
						>
							<slide v-for="(guide, idx) in LOGIN_GUIDE()" :key="idx" tabindex="1" class="info-slide">
								<div class="guide-cont">
									<div class="guide-text">
										<p class="heading-3-b title" v-html="guide.title"></p>
										<p class="body-2-m details" v-html="guide.body"></p>
									</div>
									<div class="guide-image" :class="guide.img"></div>
								</div>
							</slide>
						</carousel>
					</div>
					<!-- form -->
					<div class="login-form">
						<form>
							<fieldset>
								<!-- 제목 -->
								<legend>
									<p>다우오피스</p>
									<p class="bold">고객포털</p>
								</legend>
								<!-- 아이디 -->
								<div class="group-inp" :class="{ invalid: isInvalid.email }">
									<label class="label">아이디</label>
									<input v-model="loginId" v-on:input="handleLoginId" type="text" class="" />
								</div>
								<!-- 비밀번호 -->
								<div class="group-inp rgt-ic-16" :class="{ invalid: isInvalid.password }">
									<label class="label">비밀번호</label>
									<Password v-model="password" v-on:input="initLoginErrorMessage()"></Password>
								</div>
								<!-- 계정 기억 -->
								<div class="group-chk">
									<input v-model="useAccountMemory" type="checkbox" id="accountMemory" @click="handleAccountMemory" />
									<label for="accountMemory" class="label">계정기억</label>
								</div>
								<div v-if="loginErrorMessage" class="message-balloon">
									{{ loginErrorMessage }}
								</div>
								<!-- 인증코드 받기 //**!!! 버튼명 변경 "인증코드 받기" -> "로그인" ***// -->
								<div v-if="!isCodeVisible" @click="login" class="btn-area mt-48">
									<button class="solid full">로그인</button>
								</div>
								<div v-if="isCodeVisible" @click="reIssuanceCode" class="btn-area mt-16">
									<button class="line full">인증코드 재발급</button>
								</div>
								<div class="message-balloon" v-if="this.twofactorResult">{{ this.twofactorResult }}</div>
								<div v-if="isCodeVisible" class="group-inp">
									<label for="testButtonInput4" class="label blind">인증코드</label>
									<div class="group-inp-btn" id="testTextTime2">
										<input v-model="authCode" type="text" id="testButtonInput4" class="" value="" />
										<Countdown ref="countdown"></Countdown>
									</div>
								</div>
								<div v-if="isCodeVisible" class="btn-area mt-16">
									<button @click="confirmCode" class="solid full">인증코드 확인</button>
								</div>
							</fieldset>
						</form>
						<div class="sns-divider mt-16"><p class="body-3-r">또는 간편로그인</p></div>
						<!-- sns login -->
						<div class="group row justify-center gap32">
							<!-- 다우 오피스 로그인 -->
							<!-- <button class="sns circle">-->
							<!--	<i class="service-logo daou">다우오피스</i>-->
							<!--	<span>다우오피스 로그인</span>-->
							<!-- </button>-->
							<!-- 네이버 로그인 -->
							<button class="sns circle" type="button" @click="sns('naver')">
								<i class="service-logo naver">네이버</i>
								<span>네이버 로그인</span>
							</button>
							<!-- 카카오 로그인 -->
							<!-- <button class="sns circle" @click="sns('kakao')" disabled>
								<i class="service-logo kakao">카카오</i>
								<span>카카오 로그인</span>
							</button> -->
						</div>
					</div>
				</div>
			</div>
			<!-- footer -->
			<footer>
				<ul class="group-link">
					<li class="link">
						<a @click="showFindAccountLayer" class="" title="">계정찾기</a>
					</li>
					<li class="link">
						<a @click="showPasswordResetLayer" class="" title=""> 비밀번호 초기화 </a>
						<i class="ic-16 ic-info"
							>정보
							<div class="tooltip-layer bottom">
								소셜 회원가입 계정의 비밀번호 초기 설정 또는 비밀번호 분실시 본인 인증을 통해 비밀번호를 초기화 합니다.
								참고로 연속 5회이상 비밀번호 입력 오류시 계정이 잠겨 서비스 사용이 불가하므로 주의 바랍니다.
							</div>
						</i>
					</li>
					<li class="link">
						<a
							class=""
							title=""
							@click="
								() => {
									this.$router.replace('/signup');
								}
							"
							>회원가입</a
						>
						<i class="ic-16 ic-info" v-show="hasLinkCode"
							>정보
							<div class="tooltip-layer bottom">
								개인회원 초대코드가 활성화되어 있습니다. <br />새로고침 시에 초대코드가 삭제되니 유의바랍니다.
							</div>
						</i>
					</li>
				</ul>
			</footer>
			<br />
			<br />
			<MainFooter />
		</div>
	</Fragment>
</template>

<script>
import Password from '@/components/login/Password.vue';
import Countdown from '@/components/login/Countdown.vue';
import ConcurrentAccess from '@/components/login/ConcurrentAccessModal.vue';
import MainFooter from '@/views/common/MainFooter.vue';

import {
	CONCURRENT_ACCESS_MODAL_STYLE,
	CUSTOMER_INVITE_MODAL,
	LOGIN_GUIDE,
	NOTICE_MODAL_STYLE,
	SIGNUP_MODAL_STYLE,
} from '@/utils/constants';
import { sendAccountTwoFactor, signInConfirm } from '@/api/login';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { checkLoginId } from '@/plugins/custom/modules/validator';
import mixinsAlert from '@/components/common/mixinsAlert';
import mixinsErrCodeObserver from '@/components/exception/mixinsErrCodeObserver';
import { ALREADY_SIGNED, NOT_SIGNED } from '@/temlplates';
import { Carousel, Slide } from 'vue-carousel';
import Notice from '@/views/common/Notice';
import { NOTICE_ID, USE_NOTICE } from '@/constants';
import { getLastReadNoticeId } from '@/utils/utils';
import { mixinLinkAccount } from '@/components/login/mixinsLinkAccount';
import { getInviteCodeCookie, removeInviteCodeCookie, setInviteCodeCookie } from '@/utils/cookies';
import GnbAuthInviteModal from '@/components/login/GnbAuthInviteModal';
import FindAccountLayer from '@/components/Identified/FindAccountLayer.vue';
import ResetPasswordLayer from '@/components/Identified/ResetPasswordLayer.vue';
import { getTermDetail } from '@/api/customer';
import termsTemplate from '@/components/login/TermsDetailLayer.vue';
import { TERMS_MODAL_STYLE } from '@/utils/constants';

export default {
	components: { Countdown, Password, Carousel, Slide, MainFooter },
	mixins: [mixinsAlert, mixinsErrCodeObserver, mixinLinkAccount],
	data() {
		const useAccountMemory = JSON.parse(localStorage.getItem('useAccountMemory'));
		return {
			loginId: useAccountMemory ? localStorage.getItem('loginId') : '',
			password: '',
			isCodeVisible: false,
			backUrl: process.env.VUE_APP_API_URL,
			frontUrl: process.env.VUE_APP_FRONT_SERVER,
			authCode: '',
			twofactorResult: '',
			cookieKeys: {
				openloungeKey: 'openlounge-code',
				daouofficeKey: 'daouoffice-code',
				memberInviteKey: 'invite-code',
			},
			isInvalid: {
				email: null,
				password: null,
			},
			loginErrorMessage: '',
			useAccountMemory,
			errCodes: {
				concurrentAccess: 'ERROR0021',
			},
		};
	},
	props: {
		isLinkedAccount: { String, default: '' },
		isMainSnsId: { String, default: '' },
		signupData: { String, default: '' },
	},
	created() {
		document.body.className = '';
		this.showNotSignupModalByProps();
		this.ssoFailHandleByRedirect();
		this.validateDuplicateLinkCode();
		this.checkOpenloungeCode();
		this.checkDomsInviteCode();
		const lastReadNoticeId = getLastReadNoticeId();
		if (USE_NOTICE && NOTICE_ID > lastReadNoticeId) {
			this.$modal.show(Notice, {}, Object.assign({}, NOTICE_MODAL_STYLE, { name: 'notice', clickToClose: false }));
		}
	},
	beforeDestroy() {
		if (this.isCodeVisible) {
			this.$refs.countdown.stopCountdown();
		}
	},
	computed: {
		...mapGetters(['isTwoFactor']),
		hasNotDaouofficeCodeInQueryString() {
			return !this.$route.query?.daouofficeCode;
		},
		hasLinkCode() {
			return !_.isEmpty(this.$store.getters['getLinkCodes']);
		},
	},
	methods: {
		LOGIN_GUIDE() {
			return LOGIN_GUIDE;
		},
		handleLoginId(e) {
			if (this.useAccountMemory) localStorage.setItem('loginId', this.loginId);
			this.initLoginErrorMessage();
			if (!checkLoginId(e.currentTarget.value) || e.currentTarget.value === '') {
				this.isInvalid.email = false;
			} else {
				this.isInvalid.email = true;
			}
		},
		initLoginErrorMessage() {
			if (this.loginErrorMessage !== '') {
				this.isInvalid.email = false;
				this.isInvalid.password = false;
				this.loginErrorMessage = '';
			}
		},
		setLoginErrorMessage(message) {
			this.isInvalid.email = true;
			this.isInvalid.password = true;
			this.loginErrorMessage = message;
		},
		login(e) {
			e.preventDefault();
			this.$store
				.dispatch('login', { loginId: this.loginId, password: this.password })
				.then(() => {
					if (this.isTwoFactor) {
						this.isCodeVisible = true;
						this.$toasted.show('등록하신 이메일로 인증코드를 발송하였습니다.');
						// 추후 2차인증 선택이 가능해지면
						// this.handleConfirm();
						// this.showSelectAuthLayer();
					} else {
						this.afterLogin();
					}
				})
				.catch((response) => {
					const concurrentAccessed = this.accessConcurrent(response);
					if (concurrentAccessed) {
						return;
					}
					if (response.data.errorCode === 'ERROR211') {
						this.observeErrCode(response.data.errorCode, { loginId: this.loginId, password: this.password });
					} else {
						this.observeErrCode(response.data.errorCode, response.data.errorMessage, this.setLoginErrorMessage);
					}
				});
			// await this.$router.push('/user/myStore/SiteStatus');
			// await this.$router.push('/home');
		},
		async reIssuanceCode(e) {
			e.preventDefault();
			await this.issuanceCode();
			this.$refs.countdown.resetCountdown();
			this.$ms.toast.show('인증코드가 발송되었습니다.');
		},
		showFindAccountLayer() {
			this.$modal.show(FindAccountLayer, {}, Object.assign({}, SIGNUP_MODAL_STYLE, { name: 'findAccount' }));
		},
		showPasswordResetLayer() {
			this.$modal.show(ResetPasswordLayer, {}, Object.assign({}, SIGNUP_MODAL_STYLE, { name: 'passwordReset' }));
		},
		async issuanceCode() {
			this.twofactorResult = '';
			this.authCode = '';
			this.isCodeVisible = true;
			await sendAccountTwoFactor({
				loginId: this.loginId,
				password: this.password,
			});
		},
		async confirmCode(e) {
			e.preventDefault();
			this.twofactorResult = '';
			if (!this.authCode) {
				this.twofactorResult = '인증코드를 입력해주세요.';
				return;
			}

			try {
				const response = await signInConfirm({
					loginId: this.loginId,
					authCode: this.authCode,
				}).then(() => {
					this.afterLogin();
				});
			} catch (response) {
				const concurrentAccessed = this.accessConcurrent(response);
				if (concurrentAccessed) {
					return;
				}
				if (response.data.errorCode === 'ERROR211') {
					if (this.isCodeVisible) {
						this.$refs.countdown.stopCountdown();
					}
					this.observeErrCode(response.data.errorCode, { loginId: this.loginId, password: this.password });
				} else {
					this.twofactorResult = response.data.errorMessage;
				}
			}
		},
		sns(snsType) {
			if (snsType === 'kakao') {
				return;
			}
			const inviteCode = this.$store.getters['getInviteCode'];
			if (inviteCode) {
				setInviteCodeCookie(this.$store.getters['getInviteCode']);
			}

			location.href =
				this.backUrl + '/oauth2/authorization/' + snsType + '?redirect_uri=' + this.frontUrl + '/oauth/redirect/login';
		},
		checkOpenloungeCode() {
			// @TODO: openlounge 초대 기능 작업 필요시 재 작업 필요
			// const code = this.$route.query.code;
			// if (!code) {
			// 	return;
			// }
			// this.$cookies.set(this.cookieKeys.openloungeKey, code);
		},
		accessConcurrent(response) {
			const data = response?.data;
			if (!data || !data.status) {
				return false;
			}
			const statusCode = data.status;
			if (statusCode != '403' || data.errorCode != 'GW_ERR001') {
				return false;
			}
			const token = response.headers.authorization;
			this.$modal.show(
				ConcurrentAccess,
				{ loginId: this.loginId, token: token },
				Object.assign({}, CONCURRENT_ACCESS_MODAL_STYLE, { name: ConcurrentAccess.name, clickToClose: false }),
			);
			return true;
		},
		afterLogin() {
			// TODO: 휴면계정인지 => 가장 최근 로그인과 1년 비교
			// this.$modal.show(Dormant, { name: 'dormant' }, Object.assign({}, DEFAULT_MODAL_STYLE, { name: 'dormant' }));
			// TODO: 계정잠금인지
			// this.$modal.show(
			// 	AccountUnlock,
			// 	{ name: 'accountUnlock' },
			// 	Object.assign({}, DEFAULT_MODAL_STYLE, { name: 'accountUnlock', clickToClose: false }),
			// );
			// TODO: 비밀번호 변경
			// this.$modal.show(
			// 	PasswordChange,
			// 	{ name: 'passwordChange' },
			// 	Object.assign({}, DEFAULT_MODAL_STYLE, { name: 'passwordChange', clickToClose: false }),
			// );
			if (this.isCodeVisible) {
				this.$refs.countdown.stopCountdown();
			}
			// 개설라운지를 통한 접근
			this.linkService()
				.catch(() => {
					console.log('다우오피스 연동 실패');
				})
				.finally(() => {
					this.$router.push('/home');
				});
		},
		handleAccountMemory() {
			this.useAccountMemory = !this.useAccountMemory;
			localStorage.setItem('loginId', this.useAccountMemory ? this.loginId : '');
			localStorage.setItem('useAccountMemory', this.useAccountMemory);
		},
		async checkDomsInviteCode() {
			const inviteCode = this.$store.getters['getInviteCode'];
			if (!inviteCode) return;

			const codeInfo = await this.$store.dispatch('getIsSignupCustomer', inviteCode);
			if (!codeInfo?.domsInvite) {
				// this.clearLinkCodeInStore();
				return;
			}

			if (codeInfo?.signup) {
				this.clearLinkCodeInStore();
				this.alert({
					title: '기가입회원',
					contents: ALREADY_SIGNED(codeInfo.name, codeInfo.email, codeInfo.loginId),
				});
			} else {
				await this.$router.replace('/signup');
			}
		},
		showNotSignupModalByProps() {
			if (!_.isEmpty(this.$route.params)) {
				this.alert({
					contents: NOT_SIGNED,
					buttons: [
						{
							text: '회원가입',
							callback: () => {
								this.$router.replace('/signup');
							},
						},
						{},
					],
				});
			}
		},
		ssoFailHandleByRedirect() {
			if (this.hasNotDaouofficeCodeInQueryString) {
				return;
			}

			// daouoffic gnb로 부터 로그인 접근 중 Account Status에 의해 로그인 실패에 해당하는 경우
			const errorCode = this.$route.query?.errorCode;
			if (errorCode === 'ERROR0601') {
				this.observeErrCode(
					errorCode,
					'다우오피스 연계 인증이 불가능합니다. 다우오피스 운영팀에 문의해주세요.',
					this.$toasted.show,
				);
				return;
			}
			if (errorCode === 'ERROR0506' || errorCode === 'ERROR0507' || errorCode === 'ERROR0508') {
				this.showCustomerInviteModal();
				this.$store.commit('DELETE_DAOUOFFICE_CODE');
				return;
			}

			if (errorCode) {
				this.observeErrCode(errorCode);
			} else {
				this.alert({
					title: '연동 계정 설정',
					contents:
						'<b>다우오피스 연동 개인계정이 존재하지 않습니다. </b><br />' +
						'<b>회원가입 또는 로그인을 통해 계정 연결을 진행하시기 바랍니다.</b><br />' +
						'(다우오피스 연동은 기업계정이 아닌 개인계정으로 연동이 가능합니다.)',
				});
			}
			this.$store.commit('SET_DAOUOFFICE_CODE', this.$route.query?.daouofficeCode);
			this.$router.push('/login');
		},
		showCustomerInviteModal() {
			this.$modal.show(
				GnbAuthInviteModal,
				{ daouofficeCode: this.$route.query?.daouofficeCode },
				Object.assign({}, CUSTOMER_INVITE_MODAL, { name: 'gnbAuthInviteModal', clickToClose: false }),
			);
		},
		clearLinkCodeInStore() {
			this.$store.commit('CLEAR_LINK_CODES');
		},
		validateDuplicateLinkCode() {
			const codes = this.$store.getters['getLinkCodes'];
			let count = 0;
			for (const code in codes) {
				// 초대 코드가 실제로 담겨있어야 count를 늘려준다.
				count = codes[code] ? count + 1 : count;
			}
			if (count > 1) {
				this.alert({
					contents: '초대 코드를 중복하여 가지고 있을 수 없습니다.',
					buttons: [
						{
							text: '로그인으로',
							callback: () => {
								this.clearLinkCodeInStore();
								this.$router.replace('/login');
							},
						},
						{
							text: '일반회원가입',
							callback: () => {
								this.clearLinkCodeInStore();
								this.clearAllCode();
							},
						},
					],
				});
			}
		},
		chatHelpPopup() {
			window.open(
				'https://mydaouoffice.channel.io/support-bots/93',
				'popup_name',
				'width=420px, height=720px, location=no, top=' +
					window.screen.height +
					', left=' +
					window.screen.width +
					', status=no,scrollbars=yes',
			);
		},
	},
};
</script>

<style>
@import '../../assets/css/common.css';
@import '../../assets/css/login.css';
@import '../../assets/css/vue-carousel.css';
</style>

<template>
	<ul class="select-list">
		<li>
			<div class="group-rdo large">
				<input type="radio" id="testRadio01" name="testSelectOption" value="email" v-model="selectedMethod" />
				<label for="testRadio01" class="text-option">
					<div class="option-list">
						<p class="text-main">이메일 인증</p>
						<div class="group column gap8" :class="{ blind: selectedMethod !== 'email' }">
							<div class="group-inp block" :class="{ invalid: invalidClass.name }">
								<input
									type="text"
									id="name"
									maxlength="50"
									class=""
									placeholder="이름"
									v-model.trim="name"
									@input="
										() => {
											if (name.length !== 0) {
												this.invalidClass.name = false;
											} else {
												this.invalidClass.name = true;
											}
										}
									"
								/>
								<p v-if="invalidClass.name" class="text-detail" :class="[invalidClass.name ? 'invalid' : 'valid']">
									<i class="ic-16" :class="[invalidClass.name ? 'ic-invalid' : 'ic-valid']"></i>이름을 입력해주세요
								</p>
							</div>
							<div class="group-inp block" :class="{ invalid: invalidClass.birth }">
								<input
									type="text"
									id="birthday"
									class=""
									placeholder="회원정보에 등록한 생년월일 (ex19970415)"
									maxlength="10"
									v-model.trim="birthDay"
									@input="validateBirth"
									:class="[invalidClass.birth ? 'invalid' : 'valid']"
								/>
								<p v-if="invalidClass.birth" class="text-detail" :class="[invalidClass.birth ? 'invalid' : 'valid']">
									<i class="ic-16" :class="[invalidClass.birth ? 'ic-invalid' : 'ic-valid']"></i>생년월일을 확인해주세요
								</p>
							</div>
							<div class="group-inp block" :class="{ invalid: invalidClass.email }">
								<input
									type="text"
									id="email"
									maxlength="50"
									class=""
									placeholder="이메일 주소 (계정)"
									v-model.trim="email"
									@input="
										() => {
											this.invalidClass.email = !checkEmail(this.email);
										}
									"
								/>
								<p v-if="invalidClass.email" class="text-detail" :class="[invalidClass.email ? 'invalid' : 'valid']">
									<i class="ic-16" :class="[invalidClass.email ? 'ic-invalid' : 'ic-valid']"></i>이메일 형식을
									확인해주세요
								</p>
							</div>
							<button
								class="solid"
								:class="{ disabled: selectedMethod !== 'email' }"
								:disabled="selectedMethod !== 'email'"
								@click="handleCompleteForEmail"
							>
								계정 찾기
							</button>
						</div>
					</div>
				</label>
			</div>
		</li>
		<li>
			<div class="group-rdo large">
				<input type="radio" id="testRadio02" name="testSelectOption" value="phone" v-model="selectedMethod" />
				<label for="testRadio02" class="text-option">
					<div class="option-list">
						<p class="text-main">현재 사용중인 휴대전화로 인증(본인인증)</p>
						<div class="group column gap8" :class="{ blind: selectedMethod === 'email' }">
							<IdentityVerificationButton v-on:complete="handleComplete"></IdentityVerificationButton>
						</div>
					</div>
				</label>
			</div>
		</li>
	</ul>
</template>

<script>
import { validation } from '@/components/apply/mixinsValidation';
import IdentityVerificationButton from '../login/IdentityVerificationButton.vue';
import { checkEmail } from '../../plugins/custom/modules/validator';
import { checkIdentity } from '../login/mixinsFindUser';

export default {
	name: 'AuthenticationComp',
	components: { IdentityVerificationButton },
	mixins: [validation, checkIdentity],
	props: ['parentComponentName'],
	data() {
		return {
			selectedMethod: 'email',
			invalidClass: {
				name: '',
				birth: '',
				email: '',
			},
			email: '',
			birthDay: '',
			name: '',
			accountList: [],
		};
	},
	methods: {
		checkEmail,
		handleComplete(data) {
			let userData = {
				name: data.name,
				ci: data.ci,
				phoneNumber: data.phoneNumber,
				birthDay: data.birthday,
			};

			this.findUserByIdentityVerification(
				userData,
				null,
				(resp) => {
					this.accountList = resp.data;
					this.handleNextStep();
				},
				this.parentComponentName,
			);
		},
		validateBirth(e) {
			let originBirth = e.currentTarget.value;
			let inputBirth = originBirth.replace(/[^0-9]/g, '');
			let length = inputBirth.length;
			let resultBirth = '';

			if (originBirth.length > 10) {
				this.birthDay = originBirth.substring(0, 10);
			}

			for (let i = 0; i < 2; i++) {
				if (inputBirth.length > 4 && i === 0) {
					resultBirth += inputBirth.substring(0, 4) + '-';
					inputBirth = inputBirth.substring(4);
				} else if (length > 6 && inputBirth.length > 2 && i === 1) {
					resultBirth += inputBirth.substring(0, 2) + '-';
					inputBirth = inputBirth.substring(2);
				}
			}

			resultBirth += inputBirth;
			this.birthDay = resultBirth;
			this.invalidClass.birth = !this.checkBirth(resultBirth);
		},
		async handleCompleteForEmail() {
			if (!this.allValidationCheck()) {
				return false;
			}

			let userData = {
				name: this.name,
				email: this.email,
				birthDay: this.birthDay.replace(/-/g, ''),
			};

			await this.findUserByIdentityVerification(
				userData,
				null,
				(resp) => {
					this.accountList = resp.data;
					this.handleNextStep();
				},
				this.parentComponentName,
			);
		},
		allValidationCheck() {
			if (this.name.length === 0) {
				this.invalidClass.name = true;
			} else {
				this.invalidClass.name = false;
			}

			if (this.birthDay.length === 0 || !this.checkBirth(this.birthDay)) {
				this.invalidClass.birth = true;
			} else {
				this.invalidClass.birth = false;
			}

			if (this.email.length === 0 || !checkEmail(this.email)) {
				this.invalidClass.email = true;
			} else {
				this.invalidClass.email = false;
			}

			return !this.invalidClass.name && !this.invalidClass.birth && !this.invalidClass.email;
		},
		handleNextStep() {
			this.$emit('complete', {
				accountList: this.accountList,
			});
		},
	},
};
</script>

<style scoped></style>

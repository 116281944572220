<template>
	<Fragment>
		<input
			ref="passwordInput"
			@input="$emit('input', $event.target.value)"
			:type="isPasswordVisible ? 'text' : 'password'"
			:value="currentPassword"
			:placeholder="placeholder"
		/>
		<i
			@click="togglePassword"
			class="ic-16 ic-view"
			:class="{ 'ic-view-off': !isPasswordVisible, 'ic-view': isPasswordVisible }"
		></i>
	</Fragment>
</template>

<script>
export default {
	name: 'Password',
	props: ['placeholder', 'password'],
	data() {
		return {
			currentPassword: this.password,
			isPasswordVisible: false,
		};
	},
	methods: {
		togglePassword() {
			this.currentPassword = this.$refs.passwordInput.value;
			this.isPasswordVisible = !this.isPasswordVisible;
		},
	},
};
</script>

<style scoped></style>
